import {
	Layout,
	MailForm,
	SignupForm,
	DownloadBrochureForm,
	StatisticsWrapper,
	Faq,
	Customers,
	Features,
	Demo,
} from '../components';
import { TERMS_AND_CONDITION_LINK, PRIVACY_POLICY_LINK } from '../constants';
import Link from 'next/link';
import WatchVideoDemo from '../components/Form/watch_video_demo';

const headingList = [
	'Engage building users with the best Facilities Management chatbot',
	'Use a single platform for all your Facilities Management needs',
];

export default function Home() {
	return (
		<Layout pageName="Homepage">
			<section className="hero">
				<div className="container">
					<div className="hero__wrapper">
						<div className="hero__content">
							<div className="hero__content-heading-wrapper">
								<h1 className="hero__content-heading">
									Award-winning, Easy-to-use Facilities Management Software / CMMS
								</h1>

								{headingList.map((heading, index) => (
									<h2 className="hero__content-heading" key={index}>
										{heading}
									</h2>
								))}
							</div>

							<div className="hero__content-cta">
								<div className="cta-wrapper">
									<SignupForm
										btnText="sign up for a 14-day free trial"
										btnClass="btn btn--cta"
										type={{ name: 'trial' }}
									/>
								</div>

								<div className="brochure">
									<DownloadBrochureForm classNameBtn="btn btn--cta" />
								</div>
							</div>

							<div className="hero__content-mail">
								<div className="mail mail--w60">
									<span className="mail__header">Or Schedule In-Person Demo</span>
									<MailForm />
								</div>

								<div className="mail">
									<div className="mail__header">Or Watch Virtual Demo</div>
									<WatchVideoDemo classNameBtn="btn btn--cta-video-demo" />
								</div>
							</div>

							<div className="hero__content-email">
								<span className="mail__header">
									<Link href={'/client-feedbacks'} className="email-link">
										See what our clients are saying
									</Link>
								</span>

								<div className="mail__header">
									Or Email&nbsp;
									<a href="mailto:sales@facilitybot.co" className="email-link">
										sales@facilitybot.co
									</a>
								</div>
							</div>
						</div>

						<StatisticsWrapper />
					</div>
				</div>
			</section>

			<Demo />

			<Customers />

			<div className="bg-img__wrapper">
				<div className="bg-img bg-img--lb"></div>
				<div className="bg-img bg-img--r"></div>
				<div className="bg-img bg-img--rb"></div>

				<Features />
			</div>

			<div>
				<Faq />
			</div>
		</Layout>
	);
}
